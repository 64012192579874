import { all } from 'redux-saga/effects';

import { rallySagas } from './rally/rally-sagas';

export function * rootSaga () {
  yield all([
    ...rallySagas
  ]);
}

