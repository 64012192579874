import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { rallyUtils } from '../lib/rally-utils';

import { rallyActions } from '../redux/rally';

import {
  CHECKPOINT_TYPE_CP,
  CHECKPOINT_TYPE_CAST
} from '../lib/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  ${(props) => props.styles}
`;

const CheckpointType = styled.span`
  ${({ isTsd }) => isTsd ? '' : 'margin-right: 16px;'}
  padding: 4px;
  border: 1px solid white;
  border-radius: 4px;
  text-transform: uppercase;
  min-width: 40px;
  text-align: center;
  user-select: none;
`;

const CheckpointItem = styled.span`
  margin-right: 16px;
  min-width: 48px;
  user-select: none;
  ${(props) => props.styles}
`;

const BlindCastClickTarget = styled.div`
  position: absolute;
  z-index: 0;
  background: transparent;
  width: 100%;
  height: 100px;
`;

/*
 * Checkpoint component for the main page
 */
export class CheckpointInfo extends React.Component {

  commitBlindCast = _.debounce(() => {
    // debounced to the leading edge for 10 seconds
    // so you can spam click it during a rally without screwing up multiple blind CASTS
    this.props.commitBlindCast();
  }, 10000, {
    leading: true,
    trailing: false
  });

  render() {
    const { checkpoints, checkpointIndex, checkpointTime, rallyType, odometerCorrectionFactor } = this.props;

    const checkpoint = checkpoints[checkpointIndex];
    const nextCheckpoint = checkpoints[checkpointIndex + 1];
    const isTsd = rallyUtils.isTsd(rallyType);

    // next checkpoint is blind CAST if it's missing an odometer value
    const isNextCheckpointBlindCast =
      isTsd &&
      !!nextCheckpoint &&
      nextCheckpoint.checkpointType === CHECKPOINT_TYPE_CAST &&
      !_.isNumber(nextCheckpoint.odometer);

    const adjustedCast = (checkpoint && checkpoint.cast && odometerCorrectionFactor !== 1.0) ?
      (checkpoint.cast / odometerCorrectionFactor) : 0;
    const adjustedCastText = adjustedCast ? ` (${adjustedCast.toFixed(1)})` : '';

    return checkpoint ? (
      <Wrapper styles="margin-bottom: 8px;">
        <Wrapper>
          <CheckpointType isTsd={isTsd}>
            {checkpoint.checkpointType} {checkpoint.checkpointType !== CHECKPOINT_TYPE_CP ? `${checkpoint.cast}${adjustedCastText}` : ''}
          </CheckpointType>

          {/* display blind CAST changes */}
          {(isNextCheckpointBlindCast) && (
            <React.Fragment>
              <div>
                <span>
                  &nbsp;&rarr; Tap here for&nbsp;
                </span>
                <CheckpointType isTsd={isTsd}>
                  {nextCheckpoint.checkpointType} {nextCheckpoint.cast}
                </CheckpointType>
              </div>
              {/* big click target */}
              <BlindCastClickTarget onClick={this.commitBlindCast} />
            </React.Fragment>
          )}

          {!isTsd && (
            <CheckpointItem styles="font-size: 1.5rem;">
              {checkpointTime}
            </CheckpointItem>
          )}
        </Wrapper>
      </Wrapper>
    ) : (
      <React.Fragment />
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckpointInfo);

