import moment from 'moment';

export const timeUtils = {
  getTimeMoment,
  getAdjustedTime
};

function getTimeMoment(timeString) { // timeString like HH:MM:SS
  const result = moment();
  result.hours(timeString.substr(0, 2));
  result.minutes(timeString.substr(3, 2));
  result.seconds(timeString.substr(6, 2));
  result.milliseconds(0);
  return result;
}

function getAdjustedTime(clock, clockAdjustment) {
  return moment(clock).add(clockAdjustment, 'milliseconds');
}

