import React from 'react';
import { connect } from 'react-redux';

import { rallyActions } from '../redux/rally';

import { Column, Row } from '../lib/styles';

import Clock from '../components/clock';
import Odometer from '../components/odometer';
import Adjustments from '../components/adjustments';
import Speedometer from '../components/speedometer';
import CheckpointInfo from '../components/checkpoint-info';
import OnTimeButton from '../components/on-time-button';

class TsdLayout extends React.Component {

  render() {
    const { checkpoints, checkpointIndex } = this.props;
    const currentCheckpoint = checkpoints[checkpointIndex]; // in modern Redux this should be a selector

    return (
        <React.Fragment>
          <Column styles="height: auto;">
            <Row align="flex-start">
              <Clock />
              <Odometer />
            </Row>
            <Row>
              <Adjustments />
            </Row>
          </Column>

          <Row justifyContent="center">
            <Column>
              <CheckpointInfo />
              <Speedometer />
              {!!currentCheckpoint && (
                <OnTimeButton />
              )}
            </Column>
          </Row>
        </React.Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(TsdLayout);

