
import {
  RALLY_TYPE_TSD,
  STORAGE_KEY_ODOMETER,
  STORAGE_KEY_CAR_NUMBER,
  STORAGE_KEY_RALLY_TYPE,
  STORAGE_KEY_CHECKPOINTS,
  STORAGE_KEY_CHECKPOINT_INDEX,
  STORAGE_KEY_TIME_ALLOWANCE,
  STORAGE_KEY_MICRO_TIME_ALLOWANCE,
  STORAGE_KEY_PAUSE_AMOUNT,
  STORAGE_KEY_CLOCK_ADJUSTMENT,
  STORAGE_KEY_ODOMETER_ADJUSTMENT,
  STORAGE_KEY_ODOMETER_CORRECTION_FACTOR
} from '../../lib/constants';

import { storageUtils } from '../../lib/storage-utils';

export const rallyInitialState = {
  clock: new Date(),
  location: null,
  latitude: null,
  longitude: null,
  heading: null,
  coordinatesBuffer: [],
  bufferedOdometer: 0, // meters
  odometer: storageUtils.getItem(STORAGE_KEY_ODOMETER) || 0, // meters
  currentSpeed: 0, // m/s
  targetSpeed: 0, // m/s
  accuracy: 0, // meters
  updateRate: 0, // seconds
  updateRateBuffer: [],
  lastUpdate: null,
  carNumber: storageUtils.getItem(STORAGE_KEY_CAR_NUMBER) || 0,
  rallyType: storageUtils.getItem(STORAGE_KEY_RALLY_TYPE) || RALLY_TYPE_TSD,
  checkpoints: storageUtils.getItem(STORAGE_KEY_CHECKPOINTS) || [],
  checkpointIndex: storageUtils.getItem(STORAGE_KEY_CHECKPOINT_INDEX) || null,
  checkpointTime: '00:00:00',
  timeAllowance: storageUtils.getItem(STORAGE_KEY_TIME_ALLOWANCE) || 0, // seconds
  microTimeAllowance: storageUtils.getItem(STORAGE_KEY_MICRO_TIME_ALLOWANCE) || 0, // milliseconds
  pauseAmount: storageUtils.getItem(STORAGE_KEY_PAUSE_AMOUNT) || 0, // seconds
  clockAdjustment: storageUtils.getItem(STORAGE_KEY_CLOCK_ADJUSTMENT) || 0,
  odometerAdjustment: storageUtils.getItem(STORAGE_KEY_ODOMETER_ADJUSTMENT) || 0, // miles
  odometerCorrectionFactor: storageUtils.getItem(STORAGE_KEY_ODOMETER_CORRECTION_FACTOR) || 1.0,
  tsdTimeDifference: 0, // seconds
  scoreEstimate: null, // string, running score estimate '0.6 late'
  prevScoreEstimate: null, // string, estimate from the previous checkpoint '0.3 early'
  isGood: false, // boolean
  dataLog: []
};

export const rallyActions = {
  updateClock: (date) => ({ type: 'UPDATE_CLOCK_SAGA', date }),
  updateAccuracy: (location) => ({ type: 'UPDATE_ACCURACY_SAGA', location }),
  updateSpeed: (location) => ({ type: 'UPDATE_SPEED_SAGA', location }),
  updateCarNumber: (carNumber) => ({ type: 'UPDATE_CAR_NUMBER_SAGA', carNumber }),
  updateRallyType: (rallyType) => ({ type: 'UPDATE_RALLY_TYPE_SAGA', rallyType }),
  updateOdometer: (odometer) => ({ type: 'UPDATE_ODOMETER_SAGA', odometer }),
  resetOdometer: () => ({ type: 'RESET_ODOMETER_SAGA' }),

  resetTimeAllowance: () => ({ type: 'RESET_TIME_ALLOWANCE_SAGA' }),
  increaseTimeAllowance: (amount) => ({ type: 'INCREASE_TIME_ALLOWANCE_SAGA', amount }),
  decreaseTimeAllowance: (amount) => ({ type: 'DECREASE_TIME_ALLOWANCE_SAGA', amount }),
  resetMicroTimeAllowance: () => ({ type: 'RESET_MICRO_TIME_ALLOWANCE_SAGA' }),
  increaseMicroTimeAllowance: (amount) => ({ type: 'INCREASE_MICRO_TIME_ALLOWANCE_SAGA', amount }),
  decreaseMicroTimeAllowance: (amount) => ({ type: 'DECREASE_MICRO_TIME_ALLOWANCE_SAGA', amount }),
  resetPauseAmount: () => ({ type: 'RESET_PAUSE_AMOUNT_SAGA' }),
  increasePauseAmount: (amount) => ({ type: 'INCREASE_PAUSE_AMOUNT_SAGA', amount }),
  decreasePauseAmount: (amount) => ({ type: 'DECREASE_PAUSE_AMOUNT_SAGA', amount }),
  resetClockAdjustment: () => ({ type: 'RESET_CLOCK_ADJUSTMENT_SAGA' }),
  increaseClockAdjustment: (amount) => ({ type: 'INCREASE_CLOCK_ADJUSTMENT_SAGA', amount }),
  decreaseClockAdjustment: (amount) => ({ type: 'DECREASE_CLOCK_ADJUSTMENT_SAGA', amount }),
  resetOdometerAdjustment: () => ({ type: 'RESET_ODOMETER_ADJUSTMENT_SAGA' }),
  increaseOdometerAdjustment: (amount) => ({ type: 'INCREASE_ODOMETER_ADJUSTMENT_SAGA', amount }),
  decreaseOdometerAdjustment: (amount) => ({ type: 'DECREASE_ODOMETER_ADJUSTMENT_SAGA', amount }),
  resetOdometerCorrectionFactor: () => ({ type: 'RESET_ODOMETER_CORRECTION_FACTOR_SAGA' }),
  increaseOdometerCorrectionFactor: (amount) => ({ type: 'INCREASE_ODOMETER_CORRECTION_FACTOR_SAGA', amount }),
  decreaseOdometerCorrectionFactor: (amount) => ({ type: 'DECREASE_ODOMETER_CORRECTION_FACTOR_SAGA', amount }),

  computeOdometer: (location, prevLatitude, prevLongitude, prevHeading) => ({ type: 'COMPUTE_ODOMETER_SAGA', location, prevLatitude, prevLongitude, prevHeading }),
  computeMonteCarlo: () => ({ type: 'COMPUTE_MONTE_CARLO_SAGA' }),
  computeTsd: () => ({ type: 'COMPUTE_TSD_SAGA' }),
  computeCheckpoint: () => ({ type: 'COMPUTE_CHECKPOINT_SAGA' }),
  computeScoreEstimate: () => ({ type: 'COMPUTE_SCORE_ESTIMATE_SAGA' }),
  commitBlindCast: () => ({ type: 'COMMIT_BLIND_CAST_SAGA' }),
  commitOnTimeCheckpoint: () => ({ type: 'COMMIT_ON_TIME_CHECKPOINT_SAGA' }),

  saveCheckpoint: (checkpoint, id) => ({ type: 'SAVE_CHECKPOINT_SAGA', checkpoint, id }),
  removeCheckpoint: (id) => ({ type: 'REMOVE_CHECKPOINT_SAGA', id }),
  removeAllCheckpoints: () => ({ type: 'REMOVE_ALL_CHECKPOINTS_SAGA' }),

  playCatNoise: () => ({ type: 'PLAY_CAT_NOISE_SAGA' }),

  setClock: (clock) => ({ type: 'RALLY_STATE', clock }),
  setLocation: (location) => ({ type: 'RALLY_STATE', location }),
  setLatitude: (latitude) => ({ type: 'RALLY_STATE', latitude }),
  setLongitude: (longitude) => ({ type: 'RALLY_STATE', longitude }),
  setHeading: (heading) => ({ type: 'RALLY_STATE', heading }),
  setCoordinatesBuffer: (coordinatesBuffer) => ({ type: 'RALLY_STATE', coordinatesBuffer }),
  setBufferedOdometer: (bufferedOdometer) => ({ type: 'RALLY_STATE', bufferedOdometer }),
  setOdometer: (odometer) => ({ type: 'RALLY_STATE', odometer }),
  setCurrentSpeed: (currentSpeed) => ({ type: 'RALLY_STATE', currentSpeed }),
  setTargetSpeed: (targetSpeed) => ({ type: 'RALLY_STATE', targetSpeed }),
  setAccuracy: (accuracy) => ({ type: 'RALLY_STATE', accuracy }),
  setUpdateRate: (updateRate) => ({ type: 'RALLY_STATE', updateRate }),
  setUpdateRateBuffer: (updateRateBuffer) => ({ type: 'RALLY_STATE', updateRateBuffer }),
  setLastUpdate: (lastUpdate) => ({ type: 'RALLY_STATE', lastUpdate }),
  setCarNumber: (carNumber) => ({ type: 'RALLY_STATE', carNumber }),
  setRallyType: (rallyType) => ({ type: 'RALLY_STATE', rallyType }),
  setCheckpoints: (checkpoints) => ({ type: 'RALLY_STATE', checkpoints }),
  setCheckpointIndex: (checkpointIndex) => ({ type: 'RALLY_STATE', checkpointIndex }),
  setCheckpointTime: (checkpointTime) => ({ type: 'RALLY_STATE', checkpointTime }),
  setTimeAllowance: (timeAllowance) => ({ type: 'RALLY_STATE', timeAllowance }),
  setMicroTimeAllowance: (microTimeAllowance) => ({ type: 'RALLY_STATE', microTimeAllowance }),
  setPauseAmount: (pauseAmount) => ({ type: 'RALLY_STATE', pauseAmount }),
  setClockAdjustment: (clockAdjustment) => ({ type: 'RALLY_STATE', clockAdjustment }),
  setOdometerAdjustment: (odometerAdjustment) => ({ type: 'RALLY_STATE', odometerAdjustment }),
  setOdometerCorrectionFactor: (odometerCorrectionFactor) => ({ type: 'RALLY_STATE', odometerCorrectionFactor }),
  setTsdTimeDifference: (tsdTimeDifference) => ({ type: 'RALLY_STATE', tsdTimeDifference }),
  setScoreEstimate: (scoreEstimate) => ({ type: 'RALLY_STATE', scoreEstimate }),
  setPrevScoreEstimate: (prevScoreEstimate) => ({ type: 'RALLY_STATE', prevScoreEstimate }),
  setIsGood: (isGood) => ({ type: 'RALLY_STATE', isGood }),
  setDataLog: (dataLog) => ({ type: 'RALLY_STATE', dataLog })
};

export function rallyReducer(state = rallyInitialState, action) {

  switch (action.type) {
    case 'RALLY_STATE':
      const newState = {
        ...state,
        ...action
      };
      delete newState.type;
      return newState;

    case 'CLEAR_STATE':
      return rallyInitialState;

    default:
      return state;
  }
}

