
export const locationUtils = {
  convertToRadians,
  calculateDistance,
  convertMetersToMiles,
  convertMilesToMeters,
  convertMetersPerSecondToMilesPerHour,
  convertMilesPerHourToMetersPerSecond,
  getHeadingDelta
};

function convertToRadians(value) {
  return value * Math.PI / 180;
}

function calculateDistance(latitude1, longitude1, latitude2, longitude2) {
  // R is the radius of the earth in kilometers
  const R = 6371; // 6371.0087714 -- http://geoweb.mit.edu/~tah/12.221_2005/grs80_corr.pdf

  const deltaLatitude = locationUtils.convertToRadians(latitude2 - latitude1);
  const deltaLongitude = locationUtils.convertToRadians(longitude2 - longitude1);
  const latitude1Radians = locationUtils.convertToRadians(latitude1);
  const latitude2Radians = locationUtils.convertToRadians(latitude2);

  // a, b, c, d, and R are crappy names, but seem to be standard for the Haversine formula
  const a = Math.pow(Math.sin(deltaLatitude / 2), 2) + (
    Math.cos(latitude1Radians) *
    Math.cos(latitude2Radians) *
    Math.pow(Math.sin(deltaLongitude / 2), 2)
  );

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c * 1000;
  return d;
}

function convertMetersToMiles(meters) {
  return meters * 0.000621371;
}

function convertMilesToMeters(miles) {
  return miles * 1609.344;
}

function convertMetersPerSecondToMilesPerHour(metersPerSecond) {
  return metersPerSecond * 2.23694;
}

function convertMilesPerHourToMetersPerSecond(milesPerHour) {
  return milesPerHour / 2.23694;
}

function getHeadingDelta(heading1, heading2) {
  if (isNaN(parseFloat(heading1)) || isNaN(parseFloat(heading2))) {
    return null;
  }

  const result = heading2 - heading1;

  if (Math.abs(result) > 180) {
    // if we have like heading1 = 1 and heading2 = 359, the difference is actually only 2,
    // but simple subtraction will calculate 358
    return result > 0 ?
      (heading2 - (heading1 + 360)) : // heading1 = 1, heading2 = 359, then the heading angle decreased by 2: 359 - 361 = -2
      (heading2 - (heading1 - 360)); // heading1 = 359, heading2 = 1, then the heading angle increased by 2: 1 - (359 - 360) = 1 - (-1) = 2
  }

  return result;
}

