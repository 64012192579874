import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';

import { rallyActions } from '../redux/rally';

import { buttonStyle } from '../lib/styles';

import { Modal } from './modal';

const AdjustmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditAdjustmentsButton = styled.button`
  ${buttonStyle}
  padding: 2.25rem 0;
  margin: 1rem 0;
`;

const AdjustmentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AdjustmentButton = styled.button`
  ${buttonStyle}
  padding: 1.5rem 0;
  width: 5rem;
  margin-bottom: -1px;
`;

const AdjustmentButtonSmall = styled.button`
  ${buttonStyle}
  padding: 0;
  width: 5rem;
  height: 2.3125rem; // 37px
  margin-bottom: -1px;
`;

const AdjustmentText = styled.span`
  color: ${({ value }) => value ? 'black': 'white' };
  background: ${({ value }) => value ? 'yellow': 'none' };
  margin: 0 .5rem;
  font-size: 1.125rem;
  min-width: 3.125rem;
  text-align: center;
  user-select: none;
`;

const ChangedAdjustmentsWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  top: 180px;
`;

const ChangedAdjustment = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: .5rem;
`;

const ChangedAdjustmentLabel = styled.span`
  color: white;
  font-size: 1.125rem;
  min-width: 2rem;
  text-align: center;
  user-select: none;
`;

const modalStyle = `
  background: black;
  border-radius: 0;
  width: auto;
  padding: 0;
`;

const StackedButtons = styled.div`
  ${({ position }) => position === 'right' ? 'left: 100%;' : 'transform: translateX(-100%);'}
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export class Adjustments extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false
    };
  }

  toggleModal = (e) => {
    e && e.target.blur();
    this.setState({
      modalOpen: !this.state.modalOpen
    });
  }

  // clock adjustment
  resetClockAdjustment = () => {
    this.props.resetClockAdjustment();
  };

  increaseClockAdjustment = () => {
    this.props.increaseClockAdjustment();
  };

  decreaseClockAdjustment = () => {
    this.props.decreaseClockAdjustment();
  };

  increaseClockAdjustment1 = () => {
    this.props.increaseClockAdjustment(1000);
  };

  decreaseClockAdjustment1 = () => {
    this.props.decreaseClockAdjustment(1000);
  };

  increaseClockAdjustment5 = () => {
    this.props.increaseClockAdjustment(5000);
  };

  decreaseClockAdjustment5 = () => {
    this.props.decreaseClockAdjustment(5000);
  };

  // time allowance
  resetTimeAllowance = () => {
    this.props.resetTimeAllowance();
  };

  increaseTimeAllowance = () => {
    this.props.increaseTimeAllowance();
  };

  decreaseTimeAllowance = () => {
    this.props.decreaseTimeAllowance();
  };

  increaseTimeAllowance10 = () => {
    this.props.increaseTimeAllowance(10);
  };

  decreaseTimeAllowance10 = () => {
    this.props.decreaseTimeAllowance(10);
  };

  increaseTimeAllowance60 = () => {
    this.props.increaseTimeAllowance(60);
  };

  decreaseTimeAllowance60 = () => {
    this.props.decreaseTimeAllowance(60);
  };

  // micro time allowance
  resetMicroTimeAllowance = () => {
    this.props.resetMicroTimeAllowance();
  };

  increaseMicroTimeAllowance = () => {
    this.props.increaseMicroTimeAllowance();
  };

  decreaseMicroTimeAllowance = () => {
    this.props.decreaseMicroTimeAllowance();
  };

  increaseMicroTimeAllowance1 = () => {
    this.props.increaseMicroTimeAllowance(1000);
  };

  decreaseMicroTimeAllowance1 = () => {
    this.props.decreaseMicroTimeAllowance(1000);
  };

  // pause amount
  resetPauseAmount = () => {
    this.props.resetPauseAmount();
  };

  increasePauseAmount = () => {
    this.props.increasePauseAmount();
  };

  decreasePauseAmount = () => {
    this.props.decreasePauseAmount();
  };

  increasePauseAmount1 = () => {
    this.props.increasePauseAmount(1);
  };

  decreasePauseAmount1 = () => {
    this.props.decreasePauseAmount(1);
  };

  // odometer adjustment
  resetOdometerAdjustment = () => {
    this.props.resetOdometerAdjustment();
  };

  increaseOdometerAdjustment = () => {
    this.props.increaseOdometerAdjustment();
  };

  decreaseOdometerAdjustment = () => {
    this.props.decreaseOdometerAdjustment();
  };

  increaseOdometerAdjustmentHundredth = () => {
    this.props.increaseOdometerAdjustment(0.01);
  };

  decreaseOdometerAdjustmentHundredth = () => {
    this.props.decreaseOdometerAdjustment(0.01);
  };

  increaseOdometerAdjustmentThousandth = () => {
    this.props.increaseOdometerAdjustment(0.001);
  };

  decreaseOdometerAdjustmentThousandth = () => {
    this.props.decreaseOdometerAdjustment(0.001);
  };

  // odometer correction factor
  resetOdometerCorrectionFactor = () => {
    this.props.resetOdometerCorrectionFactor();
  };

  increaseOdometerCorrectionFactorTenth = () => {
    this.props.increaseOdometerCorrectionFactor(0.1);
  };

  decreaseOdometerCorrectionFactorTenth = () => {
    this.props.decreaseOdometerCorrectionFactor(0.1);
  };

  increaseOdometerCorrectionFactorHundredth = () => {
    this.props.increaseOdometerCorrectionFactor(0.01);
  };

  decreaseOdometerCorrectionFactorHundredth = () => {
    this.props.decreaseOdometerCorrectionFactor(0.01);
  };

  increaseOdometerCorrectionFactorThousandth = () => {
    this.props.increaseOdometerCorrectionFactor(0.001);
  };

  decreaseOdometerCorrectionFactorThousandth = () => {
    this.props.decreaseOdometerCorrectionFactor(0.001);
  };

  render() {
    const {
      clockAdjustment,
      timeAllowance,
      microTimeAllowance,
      pauseAmount,
      odometerAdjustment,
      odometerCorrectionFactor
    } = this.props;

    const clockAdjustmentDisplay = `${Math.sign(clockAdjustment) === -1 ? '-' : ''}${moment('00:00', 'mm:ss').add(Math.abs(clockAdjustment), 'milliseconds').format('s.SSS')}`;
    const timeAllowanceDisplay = `${moment('00:00', 'mm:ss').add(timeAllowance, 'seconds').format('m:ss')}`;
    const microTimeAllowanceDisplay = `${Math.sign(microTimeAllowance) === -1 ? '-' : ''}${(Math.abs(microTimeAllowance) / 1000).toFixed(3)}`;
    const pauseAmountDisplay = `${_.padStart(pauseAmount, 2, '0')}s`;
    const odometerAdjustmentDisplay = `${odometerAdjustment.toFixed(3)}`;
    const odometerCorrectionFactorDisplay = `${odometerCorrectionFactor.toFixed(3)}`;

    return (
      <React.Fragment>
        {this.state.modalOpen ? (
          <Modal onBackgroundClicked={this.toggleModal} modalStyle={modalStyle}>
            <ModalContentWrapper>
              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseClockAdjustment1}>
                    -1
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseClockAdjustment5}>
                    -5
                  </AdjustmentButtonSmall>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreaseClockAdjustment}>
                  CA-
                </AdjustmentButton>
                <AdjustmentText value={clockAdjustment} onClick={this.resetClockAdjustment}>
                  {clockAdjustmentDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increaseClockAdjustment}>
                  CA+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButtonSmall type="button" onClick={this.increaseClockAdjustment1}>
                    +1
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.increaseClockAdjustment5}>
                    +5
                  </AdjustmentButtonSmall>
                </StackedButtons>
              </AdjustmentWrapper>

              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseTimeAllowance10}>
                    -10
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseTimeAllowance60}>
                    -60
                  </AdjustmentButtonSmall>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreaseTimeAllowance}>
                  TA-
                </AdjustmentButton>
                <AdjustmentText value={timeAllowance} onClick={this.resetTimeAllowance}>
                  {timeAllowanceDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increaseTimeAllowance}>
                  TA+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButtonSmall type="button" onClick={this.increaseTimeAllowance10}>
                    +10
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.increaseTimeAllowance60}>
                    +60
                  </AdjustmentButtonSmall>
                </StackedButtons>
              </AdjustmentWrapper>

              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButton type="button" onClick={this.decreaseMicroTimeAllowance1}>
                    -1
                  </AdjustmentButton>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreaseMicroTimeAllowance}>
                  &mu;TA-
                </AdjustmentButton>
                <AdjustmentText value={microTimeAllowance} onClick={this.resetMicroTimeAllowance}>
                  {microTimeAllowanceDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increaseMicroTimeAllowance}>
                  &mu;TA+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButton type="button" onClick={this.increaseMicroTimeAllowance1}>
                    +1
                  </AdjustmentButton>
                </StackedButtons>
              </AdjustmentWrapper>

              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButton type="button" onClick={this.decreasePauseAmount1}>
                    -1
                  </AdjustmentButton>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreasePauseAmount}>
                  P-
                </AdjustmentButton>
                <AdjustmentText value={pauseAmount} onClick={this.resetPauseAmount}>
                  {pauseAmountDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increasePauseAmount}>
                  P+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButton type="button" onClick={this.increasePauseAmount1}>
                    +1
                  </AdjustmentButton>
                </StackedButtons>
              </AdjustmentWrapper>

              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseOdometerAdjustmentHundredth}>
                    -0.01
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseOdometerAdjustmentThousandth}>
                    -0.001
                  </AdjustmentButtonSmall>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreaseOdometerAdjustment}>
                  ODO-
                </AdjustmentButton>
                <AdjustmentText value={odometerAdjustment} onClick={this.resetOdometerAdjustment}>
                  {odometerAdjustmentDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increaseOdometerAdjustment}>
                  ODO+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButtonSmall type="button" onClick={this.increaseOdometerAdjustmentHundredth}>
                    +0.01
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.increaseOdometerAdjustmentThousandth}>
                    +0.001
                  </AdjustmentButtonSmall>
                </StackedButtons>
              </AdjustmentWrapper>

              <AdjustmentWrapper>
                <StackedButtons position="left">
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseOdometerCorrectionFactorHundredth}>
                    -0.01
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.decreaseOdometerCorrectionFactorThousandth}>
                    -0.001
                  </AdjustmentButtonSmall>
                </StackedButtons>
                <AdjustmentButton type="button" onClick={this.decreaseOdometerCorrectionFactorTenth}>
                  OCF-
                </AdjustmentButton>
                <AdjustmentText value={odometerCorrectionFactorDisplay - 1} onClick={this.resetOdometerCorrectionFactor}>
                  {odometerCorrectionFactorDisplay}
                </AdjustmentText>
                <AdjustmentButton type="button" onClick={this.increaseOdometerCorrectionFactorTenth}>
                  OCF+
                </AdjustmentButton>
                <StackedButtons position="right">
                  <AdjustmentButtonSmall type="button" onClick={this.increaseOdometerCorrectionFactorHundredth}>
                    +0.01
                  </AdjustmentButtonSmall>
                  <AdjustmentButtonSmall type="button" onClick={this.increaseOdometerCorrectionFactorThousandth}>
                    +0.001
                  </AdjustmentButtonSmall>
                </StackedButtons>
              </AdjustmentWrapper>
            </ModalContentWrapper>
          </Modal>
        ) : (
          <AdjustmentsWrapper>
            <EditAdjustmentsButton type="button" onClick={this.toggleModal}>
              Edit adjustments
            </EditAdjustmentsButton>

            <ChangedAdjustmentsWrapper>
              <ChangedAdjustment onClick={this.increaseClockAdjustment}>
                <ChangedAdjustmentLabel>CA</ChangedAdjustmentLabel>
                <AdjustmentText value={clockAdjustment}>
                  {clockAdjustmentDisplay}
                </AdjustmentText>
              </ChangedAdjustment>

              <ChangedAdjustment onClick={this.increaseTimeAllowance}>
                <ChangedAdjustmentLabel>TA</ChangedAdjustmentLabel>
                <AdjustmentText value={timeAllowance}>
                  {timeAllowanceDisplay}
                </AdjustmentText>
              </ChangedAdjustment>

              <ChangedAdjustment onClick={this.increaseMicroTimeAllowance}>
                <ChangedAdjustmentLabel>&mu;TA</ChangedAdjustmentLabel>
                <AdjustmentText value={microTimeAllowance}>
                  {microTimeAllowanceDisplay}
                </AdjustmentText>
              </ChangedAdjustment>

              <ChangedAdjustment onClick={this.increasePauseAmount}>
                <ChangedAdjustmentLabel>P</ChangedAdjustmentLabel>
                <AdjustmentText value={pauseAmount}>
                  {pauseAmountDisplay}
                </AdjustmentText>
              </ChangedAdjustment>

              <ChangedAdjustment onClick={this.increaseOdometerAdjustment}>
                <ChangedAdjustmentLabel>ODO</ChangedAdjustmentLabel>
                <AdjustmentText value={odometerAdjustment}>
                  {odometerAdjustmentDisplay}
                </AdjustmentText>
              </ChangedAdjustment>
            </ChangedAdjustmentsWrapper>
          </AdjustmentsWrapper>
        )}
      </React.Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Adjustments);

