import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import {
  CHECKPOINT_TYPE_CZT,
  CHECKPOINT_TYPE_CP
} from '../lib/constants';

import { rallyUtils } from '../lib/rally-utils';

import { DeleteButton } from '../components/delete-button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  ${(props) => props.styles}
`;

const ItemDescription = styled.span`
  margin-right: 4px;
  color: rgba(255, 255, 255, .6);
  user-select: none;
`;

const CheckpointType = styled.span`
  margin-right: 16px;
  padding: 4px 0;
  border: 1px solid white;
  border-radius: 4px;
  text-transform: uppercase;
  width: 40px;
  text-align: center;
  user-select: none;
`;

const CheckpointItem = styled.span`
  margin-right: ${({ marginRight }) => marginRight || '16px'};
  min-width: ${({ minWidth }) => minWidth || '48px'};
  user-select: none;
`;

export class Checkpoint extends React.Component {

  onClick = () => {
    const { checkpoint } = this.props;
    if (this.props.onClick) {
      this.props.onClick(checkpoint);
    }
  }

  onDelete = () => {
    const { checkpoint } = this.props;
    if (this.props.onDelete) {
      this.props.onDelete(checkpoint);
    }
  };

  render() {
    const { checkpoint, rallyType } = this.props;

    return (
      <Wrapper styles="margin-bottom: 8px;">
        <Wrapper onClick={this.onClick}>
          <CheckpointType>
            {checkpoint.checkpointType}
          </CheckpointType>

          <React.Fragment>
            <ItemDescription>ODO</ItemDescription>
            <CheckpointItem minWidth="36px">{_.isNumber(checkpoint.odometer) ? checkpoint.odometer.toFixed(3) : ''}</CheckpointItem>
          </React.Fragment>

          {(rallyUtils.isTsd(rallyType) && checkpoint.checkpointType !== CHECKPOINT_TYPE_CP && checkpoint.cast) && (
            <React.Fragment>
              <ItemDescription>CAST</ItemDescription>
              <CheckpointItem minWidth="20px">{checkpoint.cast}</CheckpointItem>
            </React.Fragment>
          )}

          {((rallyUtils.isMonteCarlo(rallyType) || checkpoint.checkpointType === CHECKPOINT_TYPE_CZT) && checkpoint.time) && (
            <React.Fragment>
              <ItemDescription>T</ItemDescription>
              <CheckpointItem marginRight="4px">{checkpoint.time}</CheckpointItem>
            </React.Fragment>
          )}
        </Wrapper>
        <DeleteButton onDelete={this.onDelete} deleteText="Y?" styles="font-size: 16px; padding: 8px 12px; width: auto; border: none; justify-self: flex-end;">
          X
        </DeleteButton>
      </Wrapper>
    );
  }
}

