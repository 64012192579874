import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const ScoreEstimateText = styled.span`
  color: rgba(255, 255, 255, .7);
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
`;

export class ScoreEstimate extends React.Component {

  render() {
    const { scoreEstimate } = this.props;

    return (
      <ScoreEstimateText>
        {scoreEstimate}
      </ScoreEstimateText>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

export default connect(mapStateToProps)(ScoreEstimate);

