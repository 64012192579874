
export const audioUtils = {
  playSound
};

function playSound(path) {
  const audio = new Audio(path);
  audio.play();
}

