import React from 'react';
import styled from 'styled-components';

const VersionText = styled.span`
  color: rgba(255, 255, 255, .3);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;
`;

export class Version extends React.Component {

  render() {
    return (
      <VersionText>
        4.5.6
      </VersionText>
    );
  }
}

