import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Main from './pages/Main';
import EditCheckpoints from './pages/EditCheckpoints';

class App extends React.Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/edit-checkpoints">
            <EditCheckpoints />
          </Route>
          <Route path="/">
            <Main />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default App;
