import { combineReducers } from 'redux';

import { rallyReducer, rallyInitialState } from './rally/rally-reducer';

export const initialState = {
  rallyState: rallyInitialState
};

export const rootReducer = () => combineReducers({
  rallyState: rallyReducer
});

