
export const storageUtils = {
  setItem,
  getItem
};

function setItem(key, data) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

function getItem(key) {
  const dataJson = window.window.localStorage.getItem(key);
  if (dataJson) {
    const data = JSON.parse(dataJson);
    return data;
  }
  return null;
}

