import _ from 'lodash';

import {
  ODOMETER_PROJECTION_FACTOR,
  RALLY_TYPE_TSD,
  RALLY_TYPE_MONTE_CARLO
} from './constants';

import { locationUtils } from './location-utils';

export const rallyUtils = {
  isTsd,
  isMonteCarlo,
  adjustMonteCarloTime,
  adjustOdometer,
  getProjectedOdometer,
  computeScoreEstimate
};

function isTsd(rallyType) {
  return _.includes(rallyType, RALLY_TYPE_TSD);
}

function isMonteCarlo(rallyType) {
  return _.includes(rallyType, RALLY_TYPE_MONTE_CARLO);
}

function adjustMonteCarloTime(timeMoment, carNumber, timeAllowance, microTimeAllowance, pauseAmount) {
  return timeMoment.add(carNumber, 'minutes').add(timeAllowance, 'seconds').add(microTimeAllowance, 'milliseconds').add(pauseAmount, 'seconds');
}

function adjustOdometer(odometer, odometerAdjustment, odometerCorrectionFactor) {
  return (odometer * odometerCorrectionFactor) + locationUtils.convertMilesToMeters(odometerAdjustment);
}

function getProjectedOdometer(odometer, speed) {
  // account for GPS lag by projecting the odometer slightly ahead of its current reading,
  // as GPS signals seem to arrive about a third of a second behind the car
  return odometer + (speed * ODOMETER_PROJECTION_FACTOR);
}

function computeScoreEstimate(currentMoment, checkpointMoment, currentOdometer, checkpointOdometer, currentSpeed) {
  const legTime = (checkpointOdometer - currentOdometer) / currentSpeed; // seconds
  const diffSeconds = currentMoment.add(legTime, 'seconds').diff(checkpointMoment, 'seconds', true);
  return diffSeconds;
}

