import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { rallyActions } from '../redux/rally';

import {
  RALLY_TYPE_MONTE_CARLO_EDITOR,
  CHECKPOINT_TYPE_CP
} from '../lib/constants';

import { rallyUtils } from '../lib/rally-utils';

import { Column, Row, SmallButton } from '../lib/styles';

import Clock from '../components/clock';
import Odometer from '../components/odometer';
import Speedometer from '../components/speedometer';
import CheckpointInfo from '../components/checkpoint-info';
import ScoreEstimate from '../components/score-estimate';

class MonteCarloLayout extends React.Component {

  /* button actions */

  // saves the current time and odometer as a checkpoint
  createCheckpoint = () => {
    const { odometer, currentSpeed, clock, rallyType } = this.props;
    const checkpoint = {
      checkpointType: CHECKPOINT_TYPE_CP,
      odometer: odometer,
      cast: rallyUtils.isTsd(rallyType) ? +currentSpeed.toFixed(0) : '',
      time: moment(clock).format('HH:mm:ss')
    };
    this.props.saveCheckpoint(checkpoint);
  };

  /* misc */

  isGood = (scoreEstimate) => {
    // determine if we're likely to have a good score, when the estimate is less than 1
    return !!scoreEstimate && scoreEstimate.startsWith('0.');
  };

  render() {
    const { rallyType } = this.props;

    return (
        <>
          <Column styles="height: auto;">
            <Row align="flex-start">
              <Clock />
              <Odometer />
            </Row>
            <Row align="flex-start">
              {rallyType === RALLY_TYPE_MONTE_CARLO_EDITOR ? (
                <SmallButton onClick={this.createCheckpoint}>+Checkpoint</SmallButton>
              ) : <div />}
            </Row>
          </Column>

          <Row justifyContent="center">
            <Column>
              <CheckpointInfo />
              <Speedometer />
              <ScoreEstimate />
            </Column>
          </Row>
        </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(MonteCarloLayout);

