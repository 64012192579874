import { put, select, takeLeading } from 'redux-saga/effects';
import moment from 'moment';
import _ from 'lodash';

import {
  ENABLE_DATA_LOGGING,
  STORAGE_KEY_ODOMETER,
  STORAGE_KEY_CAR_NUMBER,
  STORAGE_KEY_RALLY_TYPE,
  STORAGE_KEY_CHECKPOINTS,
  STORAGE_KEY_CHECKPOINT_INDEX,
  STORAGE_KEY_TIME_ALLOWANCE,
  STORAGE_KEY_MICRO_TIME_ALLOWANCE,
  STORAGE_KEY_PAUSE_AMOUNT,
  STORAGE_KEY_CLOCK_ADJUSTMENT,
  STORAGE_KEY_ODOMETER_ADJUSTMENT,
  STORAGE_KEY_ODOMETER_CORRECTION_FACTOR,
  CHECKPOINT_TYPE_CZT,
  CHECKPOINT_TYPE_CAST,
  CHECKPOINT_TYPE_ON_TIME,
  MAX_BUFFER_SIZE,
  MIN_BUFFER_SIZE,
  STOPPED_BUFFER_SIZE,
  MIN_ACCURACY,
  MIN_SPEED
} from '../../lib/constants';

import { locationUtils } from '../../lib/location-utils';
import { timeUtils } from '../../lib/time-utils';
import { storageUtils } from '../../lib/storage-utils';
import { rallyUtils } from '../../lib/rally-utils';
import { audioUtils } from '../../lib/audio-utils';

import { rallyActions } from './rally-reducer';

export function * updateClockSaga(action) {
  const { date } = action;
  yield put(rallyActions.setClock(date));
}

export function * updateAccuracySaga(action) {
  const { location } = action;
  yield put(rallyActions.setAccuracy(location.coords.accuracy));
}

export function * updateSpeedSaga(action) {
  const { location } = action;
  const speed = location.coords.speed || 0;
  yield put(rallyActions.setCurrentSpeed(speed));
}

export function * updateCarNumberSaga(action) {
  const { carNumber } = action;
  yield put(rallyActions.setCarNumber(carNumber));
  storageUtils.setItem(STORAGE_KEY_CAR_NUMBER, carNumber);

  // reset the index
  yield put(rallyActions.setCheckpointIndex(null));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, null);
}

export function * updateRallyTypeSaga(action) {
  const { rallyType } = action;
  yield put(rallyActions.setRallyType(rallyType));
  storageUtils.setItem(STORAGE_KEY_RALLY_TYPE, rallyType);
}

export function * updateOdometerSaga(action) {
  const { odometer } = action;
  const odometerCorrectionFactor = yield select((state) => state.rallyState.odometerCorrectionFactor);

  yield put(rallyActions.setLatitude(null));
  yield put(rallyActions.setLongitude(null));
  yield put(rallyActions.setHeading(null));

  yield put(rallyActions.setCoordinatesBuffer([]));
  yield put(rallyActions.setBufferedOdometer(0));

  const newOdometer = odometer / (odometerCorrectionFactor || 1.0);
  yield put(rallyActions.setOdometer(newOdometer));
  storageUtils.setItem(STORAGE_KEY_ODOMETER, odometer);

  yield put(rallyActions.playCatNoise());
}

export function * resetOdometerSaga(action) {
  yield put(rallyActions.setLatitude(null));
  yield put(rallyActions.setLongitude(null));
  yield put(rallyActions.setHeading(null));

  yield put(rallyActions.setCoordinatesBuffer([]));
  yield put(rallyActions.setBufferedOdometer(0));

  yield put(rallyActions.setDataLog([]));

  yield put(rallyActions.setOdometer(0));
  yield put(rallyActions.setOdometerAdjustment(0));
  yield put(rallyActions.setPauseAmount(0));
  storageUtils.setItem(STORAGE_KEY_ODOMETER, 0);
  storageUtils.setItem(STORAGE_KEY_ODOMETER_ADJUSTMENT, 0);
  storageUtils.setItem(STORAGE_KEY_PAUSE_AMOUNT, 0);

  yield put(rallyActions.playCatNoise());
}

export function * resetTimeAllowanceSaga() {
  const newTimeAllowance = 0;
  yield put(rallyActions.setTimeAllowance(newTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_TIME_ALLOWANCE, newTimeAllowance);

  // reset the index because it's likely you got lost and are on an earlier checkpoint
  yield put(rallyActions.setCheckpointIndex(null));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, null);
  yield put(rallyActions.playCatNoise());
}

export function * increaseTimeAllowanceSaga(action) {
  const { amount } = action;
  const timeAllowance = yield select((state) => state.rallyState.timeAllowance);
  const adjustment = amount || (timeAllowance < 30 ? 10 : 60);
  const newTimeAllowance = Math.min(timeAllowance + adjustment, 1170);
  yield put(rallyActions.setTimeAllowance(newTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_TIME_ALLOWANCE, newTimeAllowance);

  // reset the index because it's likely you got lost and are on an earlier checkpoint
  yield put(rallyActions.setCheckpointIndex(null));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, null);
  yield put(rallyActions.playCatNoise());
}

export function * decreaseTimeAllowanceSaga(action) {
  const { amount } = action;
  const timeAllowance = yield select((state) => state.rallyState.timeAllowance);
  const adjustment = amount || (timeAllowance <= 30 ? 10 : 60);
  const newTimeAllowance = Math.max(timeAllowance - adjustment, 0);
  yield put(rallyActions.setTimeAllowance(newTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_TIME_ALLOWANCE, newTimeAllowance);

  // reset the index because it's likely you got lost and are on an earlier checkpoint
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, null);
  yield put(rallyActions.setCheckpointIndex(null));
  yield put(rallyActions.playCatNoise());
}

export function * resetMicroTimeAllowanceSaga() {
  const newMicroTimeAllowance = 0;
  yield put(rallyActions.setMicroTimeAllowance(newMicroTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_MICRO_TIME_ALLOWANCE, newMicroTimeAllowance);
  yield put(rallyActions.playCatNoise());
}

export function * increaseMicroTimeAllowanceSaga(action) {
  const { amount } = action;
  const microTimeAllowance = yield select((state) => state.rallyState.microTimeAllowance);
  const newMicroTimeAllowance = microTimeAllowance + (amount || 100);
  yield put(rallyActions.setMicroTimeAllowance(newMicroTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_MICRO_TIME_ALLOWANCE, newMicroTimeAllowance);
  yield put(rallyActions.playCatNoise());
}

export function * decreaseMicroTimeAllowanceSaga(action) {
  const { amount } = action;
  const microTimeAllowance = yield select((state) => state.rallyState.microTimeAllowance);
  const newMicroTimeAllowance = microTimeAllowance - (amount || 100);
  yield put(rallyActions.setMicroTimeAllowance(newMicroTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_MICRO_TIME_ALLOWANCE, newMicroTimeAllowance);
  yield put(rallyActions.playCatNoise());
}

export function * resetPauseAmountSaga() {
  const newPauseAmount = 0;
  yield put(rallyActions.setPauseAmount(newPauseAmount));
  storageUtils.setItem(STORAGE_KEY_PAUSE_AMOUNT, newPauseAmount);
  yield put(rallyActions.playCatNoise());
}

export function * increasePauseAmountSaga(action) {
  const { amount } = action;
  const pauseAmount = yield select((state) => state.rallyState.pauseAmount);
  const newPauseAmount = pauseAmount + (amount || 5);
  yield put(rallyActions.setPauseAmount(newPauseAmount));
  storageUtils.setItem(STORAGE_KEY_PAUSE_AMOUNT, newPauseAmount);
  yield put(rallyActions.playCatNoise());
}

export function * decreasePauseAmountSaga(action) {
  const { amount } = action;
  const pauseAmount = yield select((state) => state.rallyState.pauseAmount);
  const newPauseAmount = Math.max(pauseAmount - (amount || 5), 0);
  yield put(rallyActions.setPauseAmount(newPauseAmount));
  storageUtils.setItem(STORAGE_KEY_PAUSE_AMOUNT, newPauseAmount);
  yield put(rallyActions.playCatNoise());
}

export function * resetClockAdjustmentSaga() {
  const newClockAdjustment = 0;
  yield put(rallyActions.setClockAdjustment(newClockAdjustment));
  storageUtils.setItem(STORAGE_KEY_CLOCK_ADJUSTMENT, newClockAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * increaseClockAdjustmentSaga(action) {
  const { amount } = action;
  const clockAdjustment = yield select((state) => state.rallyState.clockAdjustment);
  const newClockAdjustment = clockAdjustment + (amount || 100);
  yield put(rallyActions.setClockAdjustment(newClockAdjustment));
  storageUtils.setItem(STORAGE_KEY_CLOCK_ADJUSTMENT, newClockAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * decreaseClockAdjustmentSaga(action) {
  const { amount } = action;
  const clockAdjustment = yield select((state) => state.rallyState.clockAdjustment);
  const newClockAdjustment = clockAdjustment - (amount || 100);
  yield put(rallyActions.setClockAdjustment(newClockAdjustment));
  storageUtils.setItem(STORAGE_KEY_CLOCK_ADJUSTMENT, newClockAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * resetOdometerAdjustmentSaga() {
  const newOdometerAdjustment = 0;
  yield put(rallyActions.setOdometerAdjustment(newOdometerAdjustment));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_ADJUSTMENT, newOdometerAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * increaseOdometerAdjustmentSaga(action) {
  const { amount } = action;
  const odometerAdjustment = yield select((state) => state.rallyState.odometerAdjustment);
  const newOdometerAdjustment = +(odometerAdjustment + (amount || 0.05)).toFixed(3); // fixes weird floating-point issues
  yield put(rallyActions.setOdometerAdjustment(newOdometerAdjustment));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_ADJUSTMENT, newOdometerAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * decreaseOdometerAdjustmentSaga(action) {
  const { amount } = action;
  const odometerAdjustment = yield select((state) => state.rallyState.odometerAdjustment);
  const newOdometerAdjustment = +(odometerAdjustment - (amount || 0.05)).toFixed(3); // fixes weird floating-point issues
  yield put(rallyActions.setOdometerAdjustment(newOdometerAdjustment));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_ADJUSTMENT, newOdometerAdjustment);
  yield put(rallyActions.playCatNoise());
}

export function * resetOdometerCorrectionFactorSaga() {
  const newOdometerCorrectionFactor = 1.0;
  yield put(rallyActions.setOdometerCorrectionFactor(newOdometerCorrectionFactor));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_CORRECTION_FACTOR, newOdometerCorrectionFactor);
  yield put(rallyActions.playCatNoise());
}

export function * increaseOdometerCorrectionFactorSaga(action) {
  const { amount } = action;
  const odometerCorrectionFactor = yield select((state) => state.rallyState.odometerCorrectionFactor);
  const newOdometerCorrectionFactor = odometerCorrectionFactor + (amount || 0.1);
  yield put(rallyActions.setOdometerCorrectionFactor(newOdometerCorrectionFactor));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_CORRECTION_FACTOR, newOdometerCorrectionFactor);
  yield put(rallyActions.playCatNoise());
}

export function * decreaseOdometerCorrectionFactorSaga(action) {
  const { amount } = action;
  const odometerCorrectionFactor = yield select((state) => state.rallyState.odometerCorrectionFactor);
  const newOdometerCorrectionFactor = odometerCorrectionFactor - (amount || 0.1);
  yield put(rallyActions.setOdometerCorrectionFactor(newOdometerCorrectionFactor));
  storageUtils.setItem(STORAGE_KEY_ODOMETER_CORRECTION_FACTOR, newOdometerCorrectionFactor);
  yield put(rallyActions.playCatNoise());
}

export function * computeOdometerSaga(action) {
  const { location, prevLatitude, prevLongitude, prevHeading } = action;

  const { latitude, longitude, speed, heading } = location.coords;

  let isValidLocation = true;

  // ignore inaccurate locations
  if (location.coords.accuracy >= MIN_ACCURACY) {
    isValidLocation = false;
  }

  // for the first update, just set the start point
  if (!prevLatitude || !prevLongitude) {
    yield put(rallyActions.setLatitude(latitude));
    yield put(rallyActions.setLongitude(longitude));
    yield put(rallyActions.setHeading(heading));
    yield put(rallyActions.setLastUpdate(new Date()));
    isValidLocation = false;
  }

  if (isValidLocation) {
    const { coordinatesBuffer, odometer, updateRate, updateRateBuffer, lastUpdate } = yield select((state) => state.rallyState);

    // calculate the update rate
    const updateDelta = (new Date() - lastUpdate) / 1000;
    updateRateBuffer.push(updateDelta);
    const newUpdateRateBuffer = _.takeRight(updateRateBuffer, 50);
    const newUpdateRate = _.mean(newUpdateRateBuffer);
    yield put(rallyActions.setUpdateRate(newUpdateRate));
    yield put(rallyActions.setUpdateRateBuffer(newUpdateRateBuffer));
    yield put(rallyActions.setLastUpdate(new Date()));

    yield put(rallyActions.setLocation(location)); // for logging

    // shorten the buffer if we're turning
    const headingDelta = locationUtils.getHeadingDelta(heading, prevHeading);
    const isTurning = Math.abs(headingDelta) >= 3;
    let maxBufferSize = isTurning ? MIN_BUFFER_SIZE : MAX_BUFFER_SIZE;

    // use a large buffer for very low speeds (stopped)
    if (speed < MIN_SPEED) {
      maxBufferSize = STOPPED_BUFFER_SIZE;
    }

    // add to buffer
    coordinatesBuffer.push({ latitude, longitude });
    yield put(rallyActions.setCoordinatesBuffer(coordinatesBuffer));

    // compute average latitude and longitude
    const averageLatitude = _.meanBy(coordinatesBuffer, 'latitude');
    const averageLongitude = _.meanBy(coordinatesBuffer, 'longitude');

    // calculate distance from the current averaged coordinate to the previous averaged coordinate (or the starting coordinate)
    const prevToAveragedDistance = locationUtils.calculateDistance(averageLatitude, averageLongitude, prevLatitude, prevLongitude);

    // calculate the distance between the current actual location and the averaged coordinate
    // so the odometer doesn't step back
    const averagedToCurrentDistance = locationUtils.calculateDistance(latitude, longitude, averageLatitude, averageLongitude);

    if (coordinatesBuffer.length >= maxBufferSize) {
      // update the odometer -- commit the averaged point
      const newOdometer = (odometer + prevToAveragedDistance);
      yield put(rallyActions.setOdometer(newOdometer));
      storageUtils.setItem(STORAGE_KEY_ODOMETER, newOdometer);

      // update buffered odometer
      const newBufferedOdometer = averagedToCurrentDistance;
      yield put(rallyActions.setBufferedOdometer(newBufferedOdometer));

      // set prevLatitude/prevLongitude as the last averaged coordinate
      yield put(rallyActions.setLatitude(averageLatitude));
      yield put(rallyActions.setLongitude(averageLongitude));
      yield put(rallyActions.setHeading(heading));

      // clear the buffer
      yield put(rallyActions.setCoordinatesBuffer([]));
    } else {
      // calculate the buffered odometer as the distance from the previous averaged coordinate
      // to the current averaged coordinate, and then to the current actual coordinate
      const newBufferedOdometer = prevToAveragedDistance + averagedToCurrentDistance;
      yield put(rallyActions.setBufferedOdometer(newBufferedOdometer));
    }
  }

  // kick off checkpoint calculation
  yield put(rallyActions.computeCheckpoint());
}

export function * computeMonteCarloSaga(action) {
  const {
    checkpoints,
    checkpointIndex,
    carNumber,
    odometer,
    bufferedOdometer,
    currentSpeed,
    clock,
    timeAllowance,
    microTimeAllowance,
    pauseAmount,
    clockAdjustment,
    scoreEstimate,
    odometerAdjustment,
    odometerCorrectionFactor
  } = yield select((state) => state.rallyState);

  const currentMoment = timeUtils.getAdjustedTime(clock, clockAdjustment);
  const rawOdometer = odometer + bufferedOdometer;
  const adjustedOdometer = rallyUtils.adjustOdometer(rawOdometer, odometerAdjustment, odometerCorrectionFactor);
  const projectedOdometer = rallyUtils.getProjectedOdometer(adjustedOdometer, currentSpeed);

  // find the next checkpoint and index
  let index = null;
  let checkpoint;
  let checkpointTime;
  for (let i = 0; i < checkpoints.length; i++) {
    checkpoint = checkpoints[i];
    const checkpointMoment = timeUtils.getTimeMoment(checkpoint.time);
    checkpointTime = rallyUtils.adjustMonteCarloTime(checkpointMoment, carNumber, timeAllowance, microTimeAllowance, pauseAmount);

    if (currentMoment < checkpointTime) {
      index = i;
      break;
    }
  }

  if (index == null) {
    yield put(rallyActions.setCheckpointIndex(null));
    yield put(rallyActions.setTargetSpeed(0));
    yield put(rallyActions.setTargetSpeed(0));
    yield put(rallyActions.setScoreEstimate(null));
    if (scoreEstimate != null) {
      yield put(rallyActions.setPrevScoreEstimate(scoreEstimate));
    }
    return;
  }

  const timeDifference = checkpointTime.diff(currentMoment) / 1000; // fractional seconds
  const targetSpeed = (locationUtils.convertMilesToMeters(checkpoint.odometer) - projectedOdometer) / timeDifference; // odometer is in meters

  yield put(rallyActions.setTargetSpeed(targetSpeed));
  yield put(rallyActions.setCheckpointIndex(index));
  yield put(rallyActions.setCheckpointTime(checkpointTime.format('HH:mm:ss')));
  yield put(rallyActions.computeScoreEstimate());
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, index);
  if (index !== checkpointIndex) {
    // play a cat noise when the checkpoint changes
    yield put(rallyActions.playCatNoise());

    // set the previous score estimate
    yield put(rallyActions.setPrevScoreEstimate(scoreEstimate));
  }
}

export function * computeTsdSaga(action) {
  const {
    checkpoints,
    checkpointIndex,
    carNumber,
    odometer,
    bufferedOdometer,
    currentSpeed,
    clock,
    timeAllowance,
    microTimeAllowance,
    pauseAmount,
    clockAdjustment,
    scoreEstimate,
    odometerAdjustment,
    odometerCorrectionFactor,
    dataLog,
    latitude: averageLatitude,
    longitude: averageLongitude,
    location
  } = yield select((state) => state.rallyState);

  const currentMoment = timeUtils.getAdjustedTime(clock, clockAdjustment);
  const allowances = timeAllowance + (microTimeAllowance / 1000) + pauseAmount; // seconds
  const timeAdjustments = (carNumber * 60) + allowances; // seconds
  const rawOdometer = odometer + bufferedOdometer;
  const adjustedOdometer = rallyUtils.adjustOdometer(rawOdometer, odometerAdjustment, odometerCorrectionFactor);
  const projectedOdometer = rallyUtils.getProjectedOdometer(adjustedOdometer, currentSpeed);
  let currentOdometerMiles = locationUtils.convertMetersToMiles(projectedOdometer);

  // search backwards for the previous CZT
  // calculate times for all CAST changes in between
  // calculate the perfect time for the current odometer
  // compare the perfect time with now

  // get the previous CZT
  const prevCztIndex = _.findLastIndex(checkpoints, (cp) => {
    if (cp.checkpointType !== CHECKPOINT_TYPE_CZT && cp.checkpointType !== CHECKPOINT_TYPE_ON_TIME) {
      return false;
    }

    if (cp.checkpointType === CHECKPOINT_TYPE_ON_TIME) {
      const time = moment(cp.time);
      return currentMoment >= time;
    }

    const cztTime = timeUtils.getTimeMoment(cp.time).add(timeAdjustments, 'seconds').add(-15, 'seconds');
    return currentMoment >= cztTime;
  });
  const prevCzt = checkpoints[prevCztIndex];

  // stop the app from erroring out if a CZT wasn't found
  if (!prevCzt) {
    yield put(rallyActions.setCheckpointIndex(null));
    yield put(rallyActions.setTargetSpeed(0));
    yield put(rallyActions.setScoreEstimate(null));
    if (scoreEstimate != null) {
      yield put(rallyActions.setPrevScoreEstimate(scoreEstimate));
    }
    return;
  }

  const prevCztTime = prevCzt.checkpointType === CHECKPOINT_TYPE_ON_TIME ?
    moment(prevCzt.time).add(allowances, 'seconds') :
    timeUtils.getTimeMoment(prevCzt.time).add(timeAdjustments, 'seconds');

  if (prevCzt.checkpointType === CHECKPOINT_TYPE_ON_TIME) {
    // subtract the on-time checkpoint odometer from the current odometer so it acts like a CZT
    currentOdometerMiles -= prevCzt.odometer;
  }

  // calculate CAST changes
  let index = prevCztIndex;
  let currentCast = prevCzt.cast; // mph
  let totalCastSeconds = 0; // fractional seconds -- time spent under the various CASTs
  let totalCastOdometer = 0; // miles
  for (let i = prevCztIndex + 1; i < checkpoints.length; i++) { // iterate checkpoints between the previous CZT and current index
    const cp = checkpoints[i];
    if (cp.checkpointType !== CHECKPOINT_TYPE_CAST) {
      // exit if it's a CZT or ON-TIME
      break;
    }

    if (!_.isNumber(cp.odometer)) {
      continue;
    }

    const cpOdometer = prevCzt.checkpointType === CHECKPOINT_TYPE_ON_TIME ?
      cp.odometer - prevCzt.odometer :
      cp.odometer;

    // exit if the checkpoint is beyond the current CAST zone
    if (cpOdometer > currentOdometerMiles) {
      break;
    }

    const castOdometerDelta = cpOdometer - totalCastOdometer;
    const diffSeconds = castOdometerDelta / currentCast * 3600;

    currentCast = cp.cast;
    totalCastSeconds += diffSeconds;
    totalCastOdometer += castOdometerDelta;
    index = i;
  }

  // calculate the perfect time for the current odometer
  const currentOdometerDelta = currentOdometerMiles - totalCastOdometer; // miles
  const currentOdometerPerfectTime = totalCastSeconds + (currentOdometerDelta / currentCast * 3600) // seconds
  prevCztTime.add(currentOdometerPerfectTime, 'seconds');

  // compare with the current time to determine how early/late the driver is
  const perfectTimeDiffSeconds = currentMoment.diff(prevCztTime, 'seconds', true);
  const isGood = Math.abs(perfectTimeDiffSeconds) < 1;

  // data logging
  if (ENABLE_DATA_LOGGING && location) {
    const { latitude, longitude, altitude, heading } = location.coords;
    const logDataPoint = {
      timestamp: currentMoment.valueOf(), // Unix timestamp milliseconds
      odometer: currentOdometerMiles,
      clock: currentMoment.format('HH:mm:ss.SSS'),
      tsdTimeDifference: `${perfectTimeDiffSeconds < 0 ? '-' : '+'}${Math.min(Math.abs(perfectTimeDiffSeconds), 99.9).toFixed(1)}s`,
      latitude,
      longitude,
      altitude,
      heading,
      averageLatitude,
      averageLongitude
    };
    dataLog.push(logDataPoint);
    yield put(rallyActions.setDataLog(dataLog));
  }

  // update state
  yield put(rallyActions.setCheckpointIndex(index));
  yield put(rallyActions.setTsdTimeDifference(perfectTimeDiffSeconds));
  yield put(rallyActions.setIsGood(isGood));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINT_INDEX, index);
  if (index !== checkpointIndex) {
    // play a cat noise when the checkpoint changes
    yield put(rallyActions.playCatNoise());
  }
}

export function * computeCheckpointSaga(action) {
  const { rallyType } = yield select((state) => state.rallyState);

  if (rallyUtils.isMonteCarlo(rallyType)) {
    yield put(rallyActions.computeMonteCarlo());
  } else {
    yield put(rallyActions.computeTsd());
  }
}

export function * computeScoreEstimateSaga(action) {
  const {
    checkpoints,
    checkpointIndex,
    checkpointTime,
    odometer,
    bufferedOdometer,
    currentSpeed,
    clock,
    clockAdjustment,
    odometerAdjustment,
    odometerCorrectionFactor
  } = yield select((state) => state.rallyState);

  const rawOdometer = odometer + bufferedOdometer;
  const adjustedOdometer = rallyUtils.adjustOdometer(rawOdometer, odometerAdjustment, odometerCorrectionFactor);
  const projectedOdometer = rallyUtils.getProjectedOdometer(adjustedOdometer, currentSpeed);

  // avoid divide-by-zero errors
  if (!currentSpeed || checkpointIndex == null) {
    yield put(rallyActions.setScoreEstimate(null));
    return;
  }

  const checkpoint = checkpoints[checkpointIndex];
  const currentMoment = timeUtils.getAdjustedTime(clock, clockAdjustment);
  const checkpointMoment = timeUtils.getTimeMoment(checkpointTime);
  const checkpointOdometer = locationUtils.convertMilesToMeters(checkpoint.odometer);

  const scoreEstimate = rallyUtils.computeScoreEstimate(currentMoment, checkpointMoment, projectedOdometer, checkpointOdometer, currentSpeed);

  const earlyLateText = scoreEstimate < 0 ? 'early' : 'late';
  const result = `${Math.min(Math.abs(scoreEstimate), 60).toFixed(1)} ${earlyLateText}`;
  yield put(rallyActions.setScoreEstimate(result));

  const isGood = Math.abs(scoreEstimate) < 1;
  yield put(rallyActions.setIsGood(isGood));
}

export function * commitBlindCastSaga(action) {
  const { checkpoints, checkpointIndex, odometer, bufferedOdometer } = yield select((state) => state.rallyState);

  const index = checkpointIndex + 1;
  const rawOdometer = odometer + bufferedOdometer;
  checkpoints[index].odometer = +locationUtils.convertMetersToMiles(rawOdometer).toFixed(3);

  yield put(rallyActions.setCheckpoints([...checkpoints]));
  yield put(rallyActions.setCheckpointIndex(index));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINTS, checkpoints);
  yield put(rallyActions.playCatNoise());
}

export function * commitOnTimeCheckpointSaga(action) {
  const {
    microTimeAllowance,
    tsdTimeDifference
  } = yield select((state) => state.rallyState);

  const newMicroTimeAllowance = microTimeAllowance + (tsdTimeDifference * 1000);
  yield put(rallyActions.setMicroTimeAllowance(newMicroTimeAllowance));
  storageUtils.setItem(STORAGE_KEY_MICRO_TIME_ALLOWANCE, newMicroTimeAllowance);
  yield put(rallyActions.playCatNoise());
}

export function * saveCheckpointSaga(action) {
  const { checkpoint, id } = action;
  const checkpoints = yield select((state) => state.rallyState.checkpoints);

  if (id) {
    var checkpointMatch = _.find(checkpoints, { id });
    _.assign(checkpointMatch, checkpoint);
  } else {
    // add some extra properties on create only
    checkpoint.id = '_' + Math.random().toString(36).substr(2, 9);
    checkpoint.order = checkpoints.length ? _.last(checkpoints).order + 1 : 0;
    checkpoints.push(checkpoint);
  }

  yield put(rallyActions.setCheckpoints(checkpoints));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINTS, checkpoints);
  yield put(rallyActions.playCatNoise());
}

export function * removeCheckpointSaga(action) {
  const { id } = action;
  const checkpoints = yield select((state) => state.rallyState.checkpoints);

  // wish there was a good non-O(n) solution, but somehow it has to make a new array -- it's fine
  const result = _.filter(checkpoints, (checkpoint) => checkpoint.id !== id);

  yield put(rallyActions.setCheckpoints(result));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINTS, result);
  yield put(rallyActions.playCatNoise());
}

export function * removeAllCheckpointsSaga(action) {
  const checkpoints = [];
  yield put(rallyActions.setCheckpoints(checkpoints));
  storageUtils.setItem(STORAGE_KEY_CHECKPOINTS, checkpoints);
  yield put(rallyActions.playCatNoise());
}

export function playCatNoiseSaga(action) {
  const number = _.random(1, 44);
  const path = `/sounds/cat${number}.mp3`;
  audioUtils.playSound(path);
}

export const rallySagas = [
  takeLeading('UPDATE_CLOCK_SAGA', updateClockSaga),
  takeLeading('UPDATE_ACCURACY_SAGA', updateAccuracySaga),
  takeLeading('UPDATE_SPEED_SAGA', updateSpeedSaga),
  takeLeading('UPDATE_CAR_NUMBER_SAGA', updateCarNumberSaga),
  takeLeading('UPDATE_RALLY_TYPE_SAGA', updateRallyTypeSaga),
  takeLeading('UPDATE_ODOMETER_SAGA', updateOdometerSaga),
  takeLeading('RESET_ODOMETER_SAGA', resetOdometerSaga),
  takeLeading('RESET_TIME_ALLOWANCE_SAGA', resetTimeAllowanceSaga),
  takeLeading('INCREASE_TIME_ALLOWANCE_SAGA', increaseTimeAllowanceSaga),
  takeLeading('DECREASE_TIME_ALLOWANCE_SAGA', decreaseTimeAllowanceSaga),
  takeLeading('RESET_MICRO_TIME_ALLOWANCE_SAGA', resetMicroTimeAllowanceSaga),
  takeLeading('INCREASE_MICRO_TIME_ALLOWANCE_SAGA', increaseMicroTimeAllowanceSaga),
  takeLeading('DECREASE_MICRO_TIME_ALLOWANCE_SAGA', decreaseMicroTimeAllowanceSaga),
  takeLeading('RESET_PAUSE_AMOUNT_SAGA', resetPauseAmountSaga),
  takeLeading('INCREASE_PAUSE_AMOUNT_SAGA', increasePauseAmountSaga),
  takeLeading('DECREASE_PAUSE_AMOUNT_SAGA', decreasePauseAmountSaga),
  takeLeading('RESET_CLOCK_ADJUSTMENT_SAGA', resetClockAdjustmentSaga),
  takeLeading('INCREASE_CLOCK_ADJUSTMENT_SAGA', increaseClockAdjustmentSaga),
  takeLeading('DECREASE_CLOCK_ADJUSTMENT_SAGA', decreaseClockAdjustmentSaga),
  takeLeading('RESET_ODOMETER_ADJUSTMENT_SAGA', resetOdometerAdjustmentSaga),
  takeLeading('INCREASE_ODOMETER_ADJUSTMENT_SAGA', increaseOdometerAdjustmentSaga),
  takeLeading('DECREASE_ODOMETER_ADJUSTMENT_SAGA', decreaseOdometerAdjustmentSaga),
  takeLeading('RESET_ODOMETER_CORRECTION_FACTOR_SAGA', resetOdometerCorrectionFactorSaga),
  takeLeading('INCREASE_ODOMETER_CORRECTION_FACTOR_SAGA', increaseOdometerCorrectionFactorSaga),
  takeLeading('DECREASE_ODOMETER_CORRECTION_FACTOR_SAGA', decreaseOdometerCorrectionFactorSaga),
  takeLeading('COMPUTE_ODOMETER_SAGA', computeOdometerSaga),
  takeLeading('COMPUTE_MONTE_CARLO_SAGA', computeMonteCarloSaga),
  takeLeading('COMPUTE_TSD_SAGA', computeTsdSaga),
  takeLeading('COMPUTE_CHECKPOINT_SAGA', computeCheckpointSaga),
  takeLeading('COMPUTE_SCORE_ESTIMATE_SAGA', computeScoreEstimateSaga),
  takeLeading('COMMIT_BLIND_CAST_SAGA', commitBlindCastSaga),
  takeLeading('COMMIT_ON_TIME_CHECKPOINT_SAGA', commitOnTimeCheckpointSaga),
  takeLeading('SAVE_CHECKPOINT_SAGA', saveCheckpointSaga),
  takeLeading('REMOVE_CHECKPOINT_SAGA', removeCheckpointSaga),
  takeLeading('REMOVE_ALL_CHECKPOINTS_SAGA', removeAllCheckpointsSaga),
  takeLeading('PLAY_CAT_NOISE_SAGA', playCatNoiseSaga)
];

