import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { rallyActions } from '../redux/rally';

import {
  RALLY_TYPE_TSD
} from '../lib/constants';

import { Column, Row, LinkButton } from '../lib/styles';

import { Version } from '../components/version';
import { Wakelock } from '../components/wakelock';

import TsdLayout from '../components/tsd-layout';
import MonteCarloLayout from '../components/monte-carlo-layout';
import OdometerResetButton from '../components/odometer-reset-button';

const Wrapper = styled.div`
  height: 100vh;
  padding: 20px;
  overflow-x: hidden;
`;

const GoodIndicator = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: green;
  z-index: -1;
`;

class Main extends React.Component {

  constructor(props) {
    super(props);

    this.geolocationWatchId = null;
    this.clockInterval = null;
    this.rallyInterval = null;
  }

  componentDidMount() {
    // reset stuff
    this.releaseLocation();

    // start stuff
    this.startLocationUpdates();
  }

  componentWillUnmount() {
    this.releaseLocation();
  }

  /* location */

  startLocationUpdates = () => {
    if (navigator.geolocation) {
      const options = {
        enableHighAccuracy: true,
        maximumAge: 0
      };
      this.geolocationWatchId = navigator.geolocation.watchPosition(this.onLocationUpdated, this.onLocationError, options);
    }
  };

  releaseLocation = () => {
    if (this.geolocationWatchId) {
      navigator.geolocation.clearWatch(this.geolocationWatchId);
      this.geolocationWatchId = null;
    }
  };

  onLocationUpdated = (location) => {
    const { latitude, longitude, heading } = this.props;
    this.props.updateAccuracy(location);
    this.props.updateSpeed(location);
    this.props.computeOdometer(location, latitude, longitude, heading);
  }

  onLocationError = (error) => {
    // shrug
    console.log('Geolocation error:', error);
  }

  render() {
    const { rallyType, isGood } = this.props;

    return (
      <Wrapper>
        {isGood && (
          <GoodIndicator />
        )}
        <Wakelock />
        <Version />

        <Column>
          {rallyType === RALLY_TYPE_TSD ? (
            <TsdLayout />
          ) : (
            <MonteCarloLayout />
          )}

          <Row>
            <Column>
              <Row>
                <LinkButton to="/edit-checkpoints" styles="width: auto; margin-right: 4px;">
                  Edit
                </LinkButton>
                <OdometerResetButton />
              </Row>
            </Column>
          </Row>
        </Column>
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

