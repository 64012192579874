import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

const ModalBackdrop = styled.div`
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: rgba(0, 0, 0, .6);
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
`;

const ModalWindow = styled.div`
  z-index: 10;
  background: white;
  border-radius: 8px;
  width: 100%;
  max-width: 360px;
  padding: 20px;
  ${({ modalStyle }) => modalStyle}
`;

export class Modal extends React.Component {

  componentDidMount() {
    document.body.classList.add('modal-open');
    window.addEventListener("hashchange", this.onHashChange);
    window.history.pushState({}, '', '#modal-open');
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open');
    window.removeEventListener("hashchange", this.onHashChange);
    if (_.includes(window.location.href, '#modal-open')) {
      window.history.back();
    }
  }

  onHashChange = (e) => {
    if (_.includes(e.oldURL, '#modal-open') && !_.includes(e.newURL, '#modal-open')) {
      this.onBackgroundClicked();
    }
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  onBackgroundClicked = (e) => {
    const { onBackgroundClicked } = this.props;
    if (onBackgroundClicked) {
      onBackgroundClicked();
    }
  };

  render() {
    const { modalStyle } = this.props;

    return (
      <ModalBackdrop onClick={this.onBackgroundClicked}>
        <ModalWindow onClick={this.stopPropagation} modalStyle={modalStyle}>
          {this.props.children}
        </ModalWindow>
      </ModalBackdrop>
    );
  }
}

