
// global

export const ENABLE_DATA_LOGGING = true;

export const ODOMETER_PROJECTION_FACTOR = 0; // 0.36;

export const MAX_BUFFER_SIZE = 10; // 50 for GLO2

export const MIN_BUFFER_SIZE = 1; // 10 for GLO2

export const STOPPED_BUFFER_SIZE = 50; // 250 for GLO2

export const MIN_ACCURACY = 30;

export const MIN_SPEED = 0.5; // 0.3 m/s is 0.67 mph -- you're stopped

// rally types

export const RALLY_TYPE_TSD = 'tsd';

export const RALLY_TYPE_MONTE_CARLO = 'monte-carlo';

export const RALLY_TYPE_MONTE_CARLO_EDITOR = 'monte-carlo-editor';

// checkpoint types

export const CHECKPOINT_TYPE_CZT = 'czt';

export const CHECKPOINT_TYPE_CAST = 'cast';

export const CHECKPOINT_TYPE_CP = 'cp';

export const CHECKPOINT_TYPE_ON_TIME = 'on-time';

// storage keys

export const STORAGE_KEY_ODOMETER = 'odometer';

export const STORAGE_KEY_CAR_NUMBER = 'carNumber';

export const STORAGE_KEY_RALLY_TYPE = 'rallyType';

export const STORAGE_KEY_CHECKPOINTS = 'checkpoints';

export const STORAGE_KEY_CHECKPOINT_INDEX = 'checkpointIndex';

export const STORAGE_KEY_TIME_ALLOWANCE = 'timeAllowance';

export const STORAGE_KEY_MICRO_TIME_ALLOWANCE = 'microTimeAllowance';

export const STORAGE_KEY_PAUSE_AMOUNT = 'pauseAmount';

export const STORAGE_KEY_CLOCK_ADJUSTMENT = 'clockAdjustment';

export const STORAGE_KEY_ODOMETER_ADJUSTMENT = 'odometerAdjustment';

export const STORAGE_KEY_ODOMETER_CORRECTION_FACTOR = 'odometerCorrectionFactor';

