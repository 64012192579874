import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';

import { rallyActions } from '../redux/rally';

import { buttonStyle } from '../lib/styles';

const Wrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 80px;
`;

const CircleButton = styled.button`
  ${buttonStyle}
  width: 100px;
  height: 100px;
  border-radius: 100px;
  padding: 0;
`;

export class OnTimeButton extends React.Component {

  commitOnTimeCheckpoint = _.debounce((e) => {
    e.preventDefault();
    // debounced to the leading edge
    // so you can spam click it during a rally without screwing up multiple blind CASTS
    this.props.commitOnTimeCheckpoint();
  }, 5000, {
    leading: true,
    trailing: false
  });

  render() {
    return (
      <Wrapper>
        <CircleButton onClick={this.commitOnTimeCheckpoint}>
          On time!
        </CircleButton>
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(OnTimeButton);

