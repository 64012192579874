import React from 'react';
import { connect } from 'react-redux';
import { createNumberMask } from 'text-mask-addons';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

import { rallyActions } from '../redux/rally';

import { locationUtils } from '../lib/location-utils';
import { rallyUtils } from '../lib/rally-utils';
import { InputWrapper, inputStyle, inputLabelStyle, buttonStyle } from '../lib/styles';

import { Modal } from './modal';

const odometerMask = createNumberMask({
  allowDecimal: true,
  decimalLimit: 3,
  prefix: '',
  includeThousandsSeparator: false
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const OdometerText = styled.span`
  color: white;
  font-weight: bold;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
`;

const AccuracyText = styled.span`
  color: rgba(255, 255, 255, .7);
  text-align: left;
  font-size: 14px;
  user-select: none;
`;

const EditOdometerForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ModalTitle = styled.h3`
  color: black;
  text-align: left;
  margin: 0 0 12px;
  user-select: none;
`;

const InputMaskedInverse = styled(MaskedInput)`
  ${inputStyle}
  color: black;
  border-color: black;
  margin-bottom: 20px;
`;

const InputLabelInverse = styled.label`
  ${inputLabelStyle}
  color: black;
`;

const ButtonInverse = styled.button`
  ${buttonStyle}
  background-color: black;
  border-color: black;
`;

export class Odometer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editingOdometer: false,
      newOdometer: ''
    };

    this.newOdometerInput = null;
  }

  toggleEdit = () => {
    this.setState({
      editingOdometer: !this.state.editingOdometer,
      newOdometer: ''
    }, () => {
      if (this.state.editingOdometer) {
        // focus the input
        this.newOdometerInput.inputElement.focus();
      }
    });
  }

  handleInputChange = (e) => {
    const value = e.target.value || '';
    const valueSanitized = value === '0._' ? '0.' : value; // stop the page from crashing if the user enters a period/decimal before any other numbers

    this.setState({
      [e.target.name]: valueSanitized
    });
  };

  updateOdometer = (e) => {
    e.preventDefault();
    if (this.state.newOdometer !== '') {
      const newOdometer = locationUtils.convertMilesToMeters(+this.state.newOdometer);
      this.props.updateOdometer(newOdometer);
    }
    this.toggleEdit();
  };

  render() {
    const { odometer, bufferedOdometer, accuracy, currentSpeed, odometerAdjustment, odometerCorrectionFactor } = this.props;

    const rawOdometer = odometer + bufferedOdometer;
    const adjustedOdometer = rallyUtils.adjustOdometer(rawOdometer, odometerAdjustment, odometerCorrectionFactor);
    const projectedOdometer = rallyUtils.getProjectedOdometer(adjustedOdometer, currentSpeed);
    const currentOdometerMiles = locationUtils.convertMetersToMiles(projectedOdometer);

    return (
      <React.Fragment>
        <Wrapper>
          <OdometerText onClick={this.toggleEdit}>
            {currentOdometerMiles.toFixed(3)} mi
          </OdometerText>
          <AccuracyText>GPS accuracy {accuracy.toFixed(3)}</AccuracyText>
        </Wrapper>

        {this.state.editingOdometer && (
          <Modal onBackgroundClicked={this.toggleEdit}>
            <EditOdometerForm onSubmit={this.updateOdometer} noValidate>
              <ModalTitle>
                Edit odometer
              </ModalTitle>

              <InputWrapper>
                <InputMaskedInverse
                  type="text"
                  data-type="number"
                  id="newOdometer"
                  name="newOdometer"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  mask={odometerMask}
                  ref={(input) => this.newOdometerInput = input}
                  onChange={this.handleInputChange}
                  value={this.state.newOdometer}
                />
                <InputLabelInverse htmlFor="newOdometer">ODO</InputLabelInverse>
              </InputWrapper>

              <ButtonInverse type="submit">
                Save changes
              </ButtonInverse>
            </EditOdometerForm>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Odometer);

