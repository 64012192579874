import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { rallyActions } from '../redux/rally';

import { ENABLE_DATA_LOGGING } from '../lib/constants';
import { Button, buttonStyle } from '../lib/styles';

import { Modal } from './modal';

const ModalTitle = styled.h3`
  color: black;
  text-align: left;
  margin: 0 0 12px;
  user-select: none;
`;

const ButtonsWrapper = styled.div`
  display: flex;

  button:not(:last-child) {
    margin-right: .25rem;
  }

  &:not(:last-child) {
    margin: 0 0 12px;
  }
`;

const ButtonInverse = styled.button`
  ${buttonStyle}
  background-color: black;
  border-color: black;
`;

export class OdometerResetButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showConfirmResetModal: false
    };
  }

  toggleConfirmation = () => {
    this.setState({
      showConfirmResetModal: !this.state.showConfirmResetModal
    });
  };

  resetOdometer = () => {
    this.props.resetOdometer();
    this.toggleConfirmation();
  };

  exportDataLog = () => {
    const { dataLog } = this.props;
    const csvHeader = 'timestamp,odometer,clock,tsdTimeDifference,latitude,longitude,altitude,heading,averageLatitude,averageLongitude\n';
    const csv = dataLog.reduce((accumulator, { timestamp, odometer, clock, tsdTimeDifference, latitude, longitude, altitude, heading, averageLatitude, averageLongitude }) => accumulator + `${timestamp},${odometer},${clock},${tsdTimeDifference},${latitude},${longitude},${altitude},${heading},${averageLatitude},${averageLongitude}\n`, csvHeader);

    // download it
    const filename = `rally-winner-${moment().unix()}.csv`
    const blob = new Blob([csv], { type: 'text/csv' });
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  };

  render() {
    return (
      <React.Fragment>
        <Button onClick={this.toggleConfirmation}>
          Reset odometer
        </Button>

        {this.state.showConfirmResetModal && (
          <Modal onBackgroundClicked={this.toggleConfirmation}>
            <ModalTitle>
              Confirm odometer reset
            </ModalTitle>

            <ButtonsWrapper>
              <ButtonInverse type="button" onClick={this.toggleConfirmation}>
                No
              </ButtonInverse>

              <ButtonInverse type="button" onClick={this.resetOdometer}>
                Yes
              </ButtonInverse>
            </ButtonsWrapper>

            {ENABLE_DATA_LOGGING && (
              <ButtonsWrapper>
                <ButtonInverse type="button" onClick={this.exportDataLog}>
                  Export data
                </ButtonInverse>
              </ButtonsWrapper>
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(OdometerResetButton);

