import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import { rallyActions } from '../redux/rally';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 6;
`;

const ClockText = styled.span`
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  user-select: none;
`;

export class Clock extends React.Component {

  componentDidMount() {
    // reset stuff
    this.releaseClock();

    // start stuff
    this.startClock();
  }

  componentWillUnmount() {
    this.releaseClock();
  }

  startClock = () => {
    this.props.updateClock(new Date());
    this.clockInterval = setInterval(() => {
      this.props.updateClock(new Date());
    }, 50);
  };

  releaseClock = () => {
    if (this.clockInterval) {
      clearInterval(this.clockInterval);
      this.clockInterval = null;
    }
  };

  render() {
    const {
      clock,
      clockAdjustment,
    } = this.props;

    return (
      <Wrapper>
        <ClockText>
          {moment(clock).add(clockAdjustment).format('HH:mm:ss')}
        </ClockText>
      </Wrapper>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Clock);

