import React from 'react';
import { connect } from 'react-redux';
import { createNumberMask } from 'text-mask-addons';
import styled from 'styled-components';
import _ from 'lodash';

import { rallyActions } from '../redux/rally';

import {
  RALLY_TYPE_TSD,
  RALLY_TYPE_MONTE_CARLO,
  RALLY_TYPE_MONTE_CARLO_EDITOR,
  CHECKPOINT_TYPE_CZT,
  CHECKPOINT_TYPE_CAST,
  CHECKPOINT_TYPE_CP,
  STORAGE_KEY_CAR_NUMBER,
  STORAGE_KEY_RALLY_TYPE
} from '../lib/constants';

import { rallyUtils } from '../lib/rally-utils';
import { Column, Row, SmallButton, LinkButton, InputWrapper, InputLabel, InputMasked, Select, RadioWrapper, Radio, RadioLabel } from '../lib/styles';

import Clock from '../components/clock';
import { Checkpoint } from '../components/checkpoint';
import { DeleteButton } from '../components/delete-button';

const carNumberMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false
});

const odometerMask = createNumberMask({
  allowDecimal: true,
  decimalLimit: 3,
  prefix: '',
  includeThousandsSeparator: false
});

const castMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false
});

const WrapperForm = styled.form`
  height: 100vh;
  background-color: black;
  padding: 20px;
`;

const FormLabel = styled.span`
  margin-right: 8px;
  color: white;
  user-select: none;
`;

const CheckpointList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: scroll;
  max-height: 100%;
  width: 100%;
`;

const StartText = styled.span`
  display: block;
  padding-bottom: 8px;
  margin-bottom: 12px;
  color: rgba(255, 255, 255, .4);
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, .4);
  user-select: none;
`;

class EditCheckpoints extends React.Component {

  constructor(props) {
    super(props);
    const { carNumber, rallyType } = this.props;

    this.state = {
      carNumber,
      rallyType,
      editId: null,
      checkpointType: CHECKPOINT_TYPE_CZT,
      odometer: '', // miles
      cast: '', // mph
      time: ''
    };

    this.odometerInput = null;
  }

  handleInputChange = (e) => {
    let value = e.target.value;

    // handle other value types
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else if ((e.target.type === 'number' || e.target.dataset.type === 'number') && e.target.value !== '') {
      const valueSanitized = value === '0._' ? '0.' : value;
      value = valueSanitized;
    }

    this.setState({
      [e.target.name]: value
    });

    // persist the car number and rally type on edit
    if (e.target.name === STORAGE_KEY_CAR_NUMBER) {
      this.props.updateCarNumber(+value);
    } else if (e.target.name === STORAGE_KEY_RALLY_TYPE) {
      this.props.updateRallyType(value);
    }
  };

  saveCheckpoint = (e) => {
    e.preventDefault();

    // assume the form is empty if it's missing a checkpoint type and odometer
    // (not 100% accurate, but it'll work for now)
    if (!this.state.checkpointType) {
      return;
    }

    // allow blank odometer for blind checkpoints
    const odometer = this.state.odometer === '' ? '' : +this.state.odometer;

    // create the checkpoint
    const checkpoint = {
      checkpointType: this.state.checkpointType,
      odometer,
      cast: +this.state.cast,
      time: this.state.time
    };

    // save the checkpoint with redux
    this.props.saveCheckpoint(checkpoint, this.state.editId);

    // update form state
    this.setState({
      editId: null,
      //checkpointType: '', // don't reset the checkpoint type for quick adding multiple CPs
      odometer: '',
      cast: '',
      time: ''
    }, () => {
      // focus the odometer input on submit for fast editing
      if (this.odometerInput) {
        this.odometerInput.inputElement.focus();
      }
    });
  };

  reset = () => {
    this.props.removeAllCheckpoints();
  };

  editCheckpoint = (checkpoint) => {
    this.setState({
      editId: checkpoint.id,
      checkpointType: checkpoint.checkpointType,
      odometer: checkpoint.odometer,
      cast: checkpoint.cast,
      time: checkpoint.time
    });
  };

  deleteCheckpoint = (checkpoint) => {
    this.props.removeCheckpoint(checkpoint.id);
  };

  render() {
    const { checkpoints } = this.props;

    return (
      <WrapperForm onSubmit={this.saveCheckpoint} noValidate>
        <Column>
          {/* top form -- car number and rally type */}
          <Row styles="border-bottom: 1px solid white; margin: 0 -20px; padding: 0 20px 8px; width: calc(100% + 40px)">
            <LinkButton to="/" styles="font-size: 18px; padding: 8px 12px 8px 0; width: auto; margin-right: 4px; border: none;">
              <span>&lsaquo; Done</span>
            </LinkButton>

            <InputWrapper>
              <InputMasked
                type="text"
                data-type="number"
                id="carNumber"
                name={STORAGE_KEY_CAR_NUMBER}
                pattern="[0-9]*"
                inputMode="numeric"
                mask={carNumberMask}
                ref={(input) => this.odometerInput = input}
                onChange={this.handleInputChange}
                value={this.state.carNumber}
              />
              <InputLabel htmlFor="carNumber">Car #</InputLabel>
            </InputWrapper>

            <InputWrapper>
              <Select id="rallyType" name={STORAGE_KEY_RALLY_TYPE} onChange={this.handleInputChange} value={this.state.rallyType}>
                <option value={RALLY_TYPE_TSD}>TSD</option>
                <option value={RALLY_TYPE_MONTE_CARLO}>Monte carlo</option>
                <option value={RALLY_TYPE_MONTE_CARLO_EDITOR}>Monte carlo (editor)</option>
              </Select>
            </InputWrapper>
          </Row>

          {/* checkpoints list */}
          <Row>
            <Clock />

            <DeleteButton onDelete={this.reset} styles="font-size: 16px; padding: 8px 12px; width: auto; margin-top: 8px; border: none; align-self: flex-end;">
              Reset
            </DeleteButton>
          </Row>

          <Column styles="justify-content: flex-end; overflow-y: auto;">
            <CheckpointList>
              {_.orderBy(checkpoints, ['order'], ['desc']).map((checkpoint, i) => (
                <Checkpoint key={i}
                  checkpoint={checkpoint}
                  rallyType={this.state.rallyType}
                  onClick={this.editCheckpoint}
                  onDelete={() => this.deleteCheckpoint(checkpoint)}
                />
              ))}
              <StartText>Start</StartText>
            </CheckpointList>
          </Column>

          {/* bottom form -- type, time, odometer, cast, etc. */}
          <Row styles="border-top: 1px solid white; margin: 0 -20px; padding: 8px 20px 0; width: calc(100% + 40px)">
            <Column>
              <Row styles="justify-content: flex-start; margin-bottom: 4px;">
                <FormLabel>Checkpoint type:</FormLabel>

                <RadioWrapper>
                  <Radio type="radio" id="czt" name="checkpointType" value={CHECKPOINT_TYPE_CZT}
                    onChange={this.handleInputChange} checked={this.state.checkpointType === CHECKPOINT_TYPE_CZT} />
                  <RadioLabel htmlFor="czt">CZT</RadioLabel>
                </RadioWrapper>

                {rallyUtils.isMonteCarlo(this.state.rallyType) && (
                  <RadioWrapper>
                    <Radio type="radio" id="cp" name="checkpointType" value={CHECKPOINT_TYPE_CP}
                      onChange={this.handleInputChange} checked={this.state.checkpointType === CHECKPOINT_TYPE_CP} />
                    <RadioLabel htmlFor="cp">CP</RadioLabel>
                  </RadioWrapper>
                )}

                {rallyUtils.isTsd(this.state.rallyType) && (
                  <React.Fragment>
                    <RadioWrapper>
                      <Radio type="radio" id="cast" name="checkpointType" value={CHECKPOINT_TYPE_CAST}
                        onChange={this.handleInputChange} checked={this.state.checkpointType === CHECKPOINT_TYPE_CAST} />
                      <RadioLabel htmlFor="cast">CAST</RadioLabel>
                    </RadioWrapper>
                  </React.Fragment>
                )}
              </Row>

              <Row>
                <InputWrapper>
                  <InputMasked
                    type="text"
                    data-type="number"
                    id="odometer"
                    name="odometer"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    mask={odometerMask}
                    ref={(input) => this.odometerInput = input}
                    onChange={this.handleInputChange}
                    value={this.state.odometer}
                  />
                  <InputLabel htmlFor="odometer">ODO</InputLabel>
                </InputWrapper>

                {(rallyUtils.isTsd(this.state.rallyType) && this.state.checkpointType !== CHECKPOINT_TYPE_CP) && (
                  <InputWrapper>
                    <InputMasked
                      type="text"
                      data-type="number"
                      id="cast"
                      name="cast"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      mask={castMask}
                      onChange={this.handleInputChange}
                      value={this.state.cast}
                    />
                    <InputLabel htmlFor="cast">CAST</InputLabel>
                  </InputWrapper>
                )}

                {(rallyUtils.isMonteCarlo(this.state.rallyType) || this.state.checkpointType === CHECKPOINT_TYPE_CZT) && (
                  <InputWrapper>
                    <InputMasked
                      type="text"
                      id="time"
                      name="time"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                      placeholder="00:00:00"
                      onChange={this.handleInputChange}
                      value={this.state.time}
                    />
                    <InputLabel htmlFor="time">T</InputLabel>
                  </InputWrapper>
                )}

                <SmallButton type="submit">{this.state.editId ? 'Save' : '+Add'}</SmallButton>
              </Row>
            </Column>
          </Row>
        </Column>
      </WrapperForm>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCheckpoints);

