import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { rallyActions } from '../redux/rally';

import { locationUtils } from '../lib/location-utils';
import { rallyUtils } from '../lib/rally-utils';

import { Column } from '../lib/styles';

const TargetSpeed = styled.span`
  color: white;
  text-align: center;
  width: 100%;
  font-size: 8rem; // 128px
  user-select: none;
`;

const CurrentSpeed = styled.span`
  color: rgba(255, 255, 255, .7);
  text-align: center;
  width: 100%;
  font-size: 1.5rem; // 24px
  user-select: none;
`;

export class Speedometer extends React.Component {

  render() {
    const { rallyType, targetSpeed, currentSpeed, tsdTimeDifference } = this.props;

    const isTsd = rallyUtils.isTsd(rallyType);
    const isMonteCarlo = rallyUtils.isMonteCarlo(rallyType);

    return (
      <Column justifyContent="flex-start">
        {isTsd && (
          <TargetSpeed>
            {tsdTimeDifference < 0 ? '-' : '+'}
            {Math.min(Math.abs(tsdTimeDifference), 99.9).toFixed(1)}s
          </TargetSpeed>
        )}
        {isMonteCarlo && (
          <TargetSpeed>
            {locationUtils.convertMetersPerSecondToMilesPerHour(targetSpeed).toFixed(1)}
          </TargetSpeed>
        )}
        <CurrentSpeed>
          {locationUtils.convertMetersPerSecondToMilesPerHour(currentSpeed).toFixed(1)}
        </CurrentSpeed>
      </Column>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = state => {
  return {
    ...state.rallyState
  };
};

const mapDispatchToProps = {
  ...rallyActions
};

export default connect(mapStateToProps, mapDispatchToProps)(Speedometer);

