import { Link } from 'react-router-dom';
import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

// layout

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  height: 100%;
  width: 100%;
  ${(props) => props.styles}
`;

export const Row = styled.div`
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justifyContent || 'space-between'};
  width: 100%;
  ${(props) => props.styles}
`;

// buttons

export const buttonStyle = `
  display: inline-block;
  width: 100%;
  background: none;
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  padding: 12px 20px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  white-space: nowrap;
  user-select: none;

  &:hover {
    color: black;
    background-color: white;
  }
`;

export const Button = styled.button`
  ${buttonStyle}
  ${(props) => props.styles}
`;

export const LinkButton = styled(Link)`
  ${buttonStyle}
  ${(props) => props.styles}
`;

export const SmallButton = styled.button`
  ${buttonStyle}
  font-size: 16px;
  padding: 8px 12px;
  width: auto;
`;

// forms

export const inputStyle = `
  background: none;
  border-radius: 4px;
  width: 100%;
  height: 37px;
  border: 1px solid white;
  font-size: 16px;
  text-align: right;
  padding-right: 8px;
  color: white;

  &:focus,
  &:active {
    background: none;
  }
`;

export const InputWrapper = styled.div`
  height: 100%;
  position: relative;
  margin-right: 4px;
  width: 100%;
  ${(props) => props.styles}
`;

export const Input = styled.input`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const InputMasked = styled(MaskedInput)`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const Select = styled.select`
  ${inputStyle}
  ${(props) => props.styles}
`;

export const inputLabelStyle = `
  top: 9px;
  left: 8px;
  position: absolute;
  color: white;
  user-select: none;
`;

export const InputLabel = styled.label`
  ${inputLabelStyle}
  ${(props) => props.styles}
`;

export const RadioWrapper = styled.div`
  height: 100%;
  position: relative;
  margin-right: 4px;
  ${(props) => props.styles}
`;

export const Radio = styled.input`
  display: none;
  ${(props) => props.styles}

  &:checked + label {
    background-color: white;
    color: black;
  }
`;

export const RadioLabel = styled.label`
  ${buttonStyle}
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  ${(props) => props.styles}
`;

