import React from 'react';

export class Wakelock extends React.Component {

  constructor(props) {
    super(props);
    this.wakeLock = null;
  }

  componentDidMount() {
    this.releaseWakelock();
    this.acquireWakelock();
  }

  componentWillUnmount() {
    this.releaseWakelock();
  }

  // see https://github.com/jampy/react-wakelock
  acquireWakelock = async () => {
    if (navigator.wakeLock) {
      navigator.wakeLock.request('screen')
        .then((wakeLock) => {
          this.wakeLock = wakeLock;
        })
        .catch(() => {
          this.wakeLock = null;
        });
    }
  };

  releaseWakelock = () => {
    if (this.wakeLock) {
      this.wakeLock.release()
        .then(() => {
          this.wakeLock = null;
        });
    }
  };

  render() {
    return <React.Fragment />;
  }
}

